import { userService } from '@/_services/user.service'
import { paymentService } from '@/_services/payment.service'
import router from '@/router'

export default {
  login({ commit }, { email, password }) {
    commit('loginRequest', { email })
    return userService.login(email, password)
      .then(
        user => {
          commit('loginSuccess', user.data)
          router.push('/')
          return Promise.resolve()
        },
        error => {
          commit('loginFailure', error)
          // dispatch('alert/error', error, { root: true })
          return Promise.reject()
        },
      )
  },
  logout() {
    return userService.logout()
      .then(
        () => {
          router.push('/login')
          return Promise.resolve()
        },
        () => Promise.reject(),
      )
  },
  forgotPassword(_, { email }) {
    return userService.forgotPassword(email)
  },
  resetPassword(_, {
    token,
    email,
    password,
    password_confirmation,
  }) {
    return userService.resetPassword(token, email, password, password_confirmation)
  },
  conektaReturn(_, { orderId }) {
    return paymentService.conektaReturn(orderId).then(
      response => Promise.resolve(response),
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  activateAccount(_, { token, password, password_confirmation }) {
    return userService.activateAccount(token, password, password_confirmation).then(
      response => Promise.resolve(response),
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  updateUserInformation({ commit }) {
    return userService.updateUserInformation().then(
      user => {
        commit('loginSuccess', user.data)
        return Promise.resolve()
      },
    )
  },
}
