import state from './moduleProductManagementState'
import mutations from './moduleProductManagementMutations'
import actions from './moduleProductManagementActions'
import getters from './moduleProductManagementGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
