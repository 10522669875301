import axios from '@/libs/axios'
import { userService } from '@/_services/user.service'

function handleResponse(response) {
  console.log(response)
  if (response.status === 401) {
    userService.logout()
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Por motivos de seguridad se ha cerrado la sesión')
  }
  return Promise.resolve(response)
}

function index() {
  return axios.get('/addresses').then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function show(id) {
  return axios.get(`/addresses/${id}`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function update(id, params, withDefault) {
  let data
  if (!withDefault) {
    data = {
      _method: 'patch',
      name: params.name,
      postalcode: params.postalcode,
      city: params.city,
      address: params.address,
      state: params.state,
      phone: params.phone,
      interior_number: params.interior_number,
      outdoor_number: params.outdoor_number,
      references: params.references,
      suburb: params.suburb,
      country_id: params.country_id.value,
    }
  } else {
    data = {
      _method: 'patch',
      name: params.name,
      postalcode: params.postalcode,
      city: params.city,
      address: params.address,
      state: params.state,
      phone: params.phone,
      interior_number: params.interior_number,
      outdoor_number: params.outdoor_number,
      references: params.references,
      suburb: params.suburb,
      country_id: params.country_id.value,
      is_default: params.is_default,
    }
  }
  return axios.post(`/addresses/${id}`, data).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function store(params) {
  return axios.post('/addresses', {
    name: params.name,
    postalcode: params.postalcode,
    city: params.city,
    address: params.address,
    state: params.state,
    phone: params.phone,
    interior_number: params.interior_number,
    outdoor_number: params.outdoor_number,
    references: params.references,
    suburb: params.suburb,
    country_id: params.country_id.value,
    is_default: 0,
  }).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function destroy(id) {
  return axios.delete(`/addresses/${id}`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response))
}

// eslint-disable-next-line import/prefer-default-export
export const addressService = {
  index,
  show,
  update,
  store,
  destroy,
}
