export default {
  success(state, message) {
    state.type = 'success'
    state.message = message
  },
  error(state, message) {
    state.type = 'danger'
    state.message = message
  },
  clear(state) {
    state.type = null
    state.message = null
  },
}
