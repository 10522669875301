import state from './moduleAccountManagementState'
import mutations from './moduleAccountManagementMutations'
import actions from './moduleAccountManagementActions'

export default {
  namespaced: true,
  state: state.user,
  mutations,
  actions,
}
