import axios from '@/libs/axios'

function logout() {
  return axios.post('/logout')
    .then(() => {
      localStorage.removeItem('user')
      // eslint-disable-next-line no-restricted-globals
      setTimeout(() => location.reload(), 100)
      return Promise.resolve()
    })
    .catch(error => {
      localStorage.removeItem('user')
      // eslint-disable-next-line no-restricted-globals
      setTimeout(() => location.reload(), 100)
      return Promise.reject(error)
    })
}

function handleResponse(response) {
  console.log(response)
  if (response.status === 401) {
    logout()
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Por motivos de seguridad se ha cerrado la sesión')
  }
  return Promise.resolve(response)
}

function me() {
  return axios.get('/user')
    .then(
      result => handleResponse(result),
      error => {
        const { response } = error
        return handleResponse(response)
      },
    )
    .then(result => Promise.resolve(result))
    .catch(error => Promise.reject(error))
}

function login(email, password) {
  const params = new URLSearchParams()
  params.append('email', email)
  params.append('password', password)

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
  }

  // eslint-disable-next-line no-unused-vars
  return axios.get('/sanctum/csrf-cookie').then(response => axios.post('/login', params, config)
    .then(() => me()
      .then(user => {
        localStorage.setItem('user', JSON.stringify(user.data))
        return Promise.resolve(user)
      }))
    .catch(error => Promise.reject(error))
    .catch(error => Promise.reject(error)))
}

function updateUserInformation() {
  return me().then(user => {
    localStorage.setItem('user', JSON.stringify(user.data))
    return Promise.resolve(user)
  })
    .catch(error => Promise.reject(error))
}

function forgotPassword(email) {
  return axios.get('/sanctum/csrf-cookie').then(
    // eslint-disable-next-line no-unused-vars
    response => axios.post('/password/email', { email }).then(
      resp => Promise.resolve(resp.data),
    ),
  )
}

function activateAccount(token, password, passwordConfirmation) {
  // eslint-disable-next-line no-unused-vars
  return axios.get('/sanctum/csrf-cookie').then(response => axios.post(`/auth/invitations/activation/${token}`, {
    password,
    password_confirmation: passwordConfirmation,
  })
    .then(resp => Promise.resolve(resp.data),
      error => Promise.reject(error)))
}

// eslint-disable-next-line camelcase
function resetPassword(token, email, password, password_confirmation) {
  return axios.post('password/reset', {
    token,
    email,
    password,
    password_confirmation,
  }).then(response => Promise.resolve(response.data))
}

// eslint-disable-next-line import/prefer-default-export
export const userService = {
  login,
  me,
  logout,
  forgotPassword,
  resetPassword,
  activateAccount,
  updateUserInformation,
}
