import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Inicio',
        breadcrumb: [
          {
            text: 'Inicio',
            active: true,
          },
        ],
      },
    },
    {
      path: '/products',
      name: 'products',
      component: () => import('@/views/e-commerce/e-commerce-shop/ECommerceShop'),
      props: true,
      meta: {
        contentRenderer: 'sidebar-left-detached',
        contentClass: 'ecommerce-application',
        pageTitle: 'Productos',
        breadcrumb: [
          {
            text: 'Productos',
            active: true,
          },
        ],
      },
    },
    {
      path: '/orders',
      name: 'orders-list',
      component: () => import('@/views/pages/orders/index/InvoiceList'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Ordenes',
        breadcrumb: [
          {
            text: 'Ordenes',
            active: true,
          },
        ],
      },
    },
    {
      path: '/new-arrivals',
      name: 'new-arrivals',
      component: () => import('@/views/pages/collections/NewArrivals.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Nuevos Lanzamientos',
        breadcrumb: [
          {
            text: 'New Arrivals',
            active: true,
          },
        ],
      },
    },
    {
      path: '/outlet',
      name: 'outlet',
      component: () => import('@/views/pages/collections/Outlet.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Outlet',
      },
    },
    {
      path: '/liquidacion',
      name: 'liquidacion',
      component: () => import('@/views/pages/collections/Liquidacion.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Outlet',
      },
    },
    {
      path: '/envio-rapido',
      name: 'envio-rapido',
      component: () => import('@/views/pages/collections/Outlet.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Outlet',
      },
    },
    {
      path: '/orders/:id',
      name: 'orders-show',
      component: () => import('@/views/pages/orders/show/InvoicePreview'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Muestra de orden',
        breadcrumb: [
          {
            text: 'Ordenes',
          },
          {
            text: 'Mostrar',
            active: true,
          },
        ],
      },
    },

    {
      path: '/products/:slug',
      name: 'product-details',
      component: () => import('@/views/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue'),
      meta: {
        pageTitle: 'Detalle del Producto',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Productos',
          },
          {
            text: 'Detalles',
            active: true,
          },
        ],
      },
    },
    {
      path: '/points',
      name: 'points',
      component: () => import('@/views/pages/points/pointsList.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Puntos',
        breadcrumb: [
          {
            text: 'Historial',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile',
      name: 'profile-information',
      component: () => import('@/views/pages/profile/ProfileInformation.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Profile',
        breadcrumb: [
          {
            text: 'Profile',
            active: true,
          },
        ],
      },
    },
    {
      path: '/all-orders',
      name: 'all-orders',
      component: () => import('@/views/pages/orders/Orders.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Orders',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profile-rewards',
      name: 'profile-rewards',
      component: () => import('@/views/pages/profile/ProfileRewards.vue'),
      meta: {
        contentClass: 'ecommerce-application',
        pageTitle: 'Profile Rewards',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/order-confirmation',
      name: 'order-confirmation',
      component: () => import('@/views/pages/orders/OrderConfirmation.vue'),
      meta: {
        contentClass: 'order-confirmation',
        pageTitle: 'Order Confirmation',
        breadcrumb: [
          {
            text: 'Orders',
            active: true,
          },
        ],
      },
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: () => import('@/views/e-commerce/e-commerce-checkout/ECommerceCheckout.vue'),
      meta: {
        pageTitle: 'Checkout',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Productos',
          },
          {
            text: 'Checkout',
            active: true,
          },
        ],
      },
    },
    {
      path: '/preview',
      name: 'preview',
      component: () => import('@/views/e-commerce/e-commerce-checkout/CheckoutPreview.vue'),
      meta: {
        pageTitle: 'Cotización',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Checkout',
          },
          {
            text: 'Cotización',
            active: true,
          },
        ],
      },
    },
    {
      path: '/checkout/pending/bank-information',
      name: 'bank-information',
      component: () => import('@/views/e-commerce/e-commerce-checkout/ECommerceBankInformation.vue'),
      meta: {
        pageTitle: 'Información bancaria',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Checkout',
          },
          {
            text: 'Información bancaria',
            active: true,
          },
        ],
      },
    },
    {
      path: '/return',
      name: 'payment-return',
      component: () => import('@/views/pages/redirect-payment/Redirect.vue'),
      meta: {
        pageTitle: 'Estado del pago',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Checkout',
          },
          {
            text: 'Resultado',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payment-success',
      name: 'payment-success',
      component: () => import('@/views/pages/redirect-payment/components/Success'),
      meta: {
        pageTitle: 'Estado del pago',
        contentClass: 'ecommerce-application',
        breadcrumb: [
          {
            text: 'Checkout',
          },
          {
            text: 'Resultado',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/pages/authentication/Login-v1.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import('@/views/pages/authentication/ForgotPassword-v1'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import('@/views/pages/authentication/ResetPassword-v1'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/invitation/activation',
      name: 'activate-account',
      component: () => import('@/views/pages/authentication/Activation'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/pages/authentication/Logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/terminos-y-condiciones',
      name: 'terminos',
      component: () => import('@/views/pages/terms/terms.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/aviso-privacidad',
      name: 'aviso',
      component: () => import('@/views/pages/terms/aviso.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/forgot-password', '/reset-password', '/invitation/activation', '/terminos-y-condiciones', '/aviso-privacidad']
  const authRequired = !publicPages.includes(to.matched[0].path)
  const loggedIn = localStorage.getItem('user')

  if (authRequired && !loggedIn) {
    return next('login')
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
