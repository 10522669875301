export default {
  setOrders(state, orders) {
    state.orders = orders
  },
  setOrder(state, order) {
    state.order = order
  },
  setInvoice(state, invoice) {
    state.invoice = invoice
  },
}
