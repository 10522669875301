import state from './moduleAddressManagementState'
import mutations from './moduleAddressManagementMutations'
import actions from './moduleAddressManagementActions'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
