import state from './moduleAlertManagementState'
import actions from './moduleAlertManagementActions'
import mutations from './moduleAlertManagementMutations'

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
