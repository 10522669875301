import axios from '@/libs/axios'
import { userService } from '@/_services/user.service'

function handleResponse(response) {
  console.log(response)
  if (response.status === 401) {
    userService.logout()
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Por motivos de seguridad se ha cerrado la sesión')
  }
  return Promise.resolve(response)
}

function conektaReturn(orderId) {
  return axios.get(`/conekta/return?order_id=${orderId}`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

// eslint-disable-next-line import/prefer-default-export
export const paymentService = {
  conektaReturn,
}
