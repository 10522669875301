export default {
  setProducts(state, products) {
    state.products = products
  },
  setCategories(state, categories) {
    const categoriesFetch = []
    categories.data.taxonomies.forEach(category => {
      category.taxons.forEach(taxon => {
        categoriesFetch.push({
          text: taxon.name,
          value: `${category.slug}/${taxon.slug}`,
        })
      })
    })
    state.categories = categoriesFetch
  },
  setBrands(state, brands) {
    const brandsFetch = []
    brands.data.brands.forEach(item => {
      item.property_values.forEach(brand => {
        brandsFetch.push({
          text: brand.title,
          value: brand.value,
        })
      })
    })
    state.brands = brandsFetch
  },
  setProductsInCart(state, products) {
    state.cart = products
  },
  setVariants(state, variants) {
    state.variants = variants
  },
}
