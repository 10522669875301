import axios from '@/libs/axios'
import { userService } from '@/_services/user.service'

function handleResponse(response) {
  console.log(response)
  if (response.status === 401) {
    userService.logout()
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Por motivos de seguridad se ha cerrado la sesión')
  }
  if (response.status === 422) {
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

function index(currentPage) {
  return axios.get(`/order?page=${currentPage}`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function indexOrdersWithPoints(currentPage) {
  return axios.get(`/order?page=${currentPage}&payment_method=2`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function show(orderId) {
  return axios.get(`/order/${orderId}`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function createInvoice(orderId, rfc, cfdiUse, regimenFiscal, razonSocial, domicilioFiscal, formaPago) {
  return axios.post(`/order/${orderId}/invoice/store`, {
    rfc,
    cfdi_use: cfdiUse,
    regimen_fiscal: regimenFiscal,
    razon_social: razonSocial,
    domicilio_fiscal: domicilioFiscal,
    forma_pago: formaPago,
  }).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function downloadInvoice(orderId) {
  return axios.get(`/order/${orderId}/invoice/download-xml`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function downloadInvoicePdf(orderId) {
  return axios.get(`/order/${orderId}/invoice/download-pdf`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

// eslint-disable-next-line import/prefer-default-export
export const orderService = {
  index,
  indexOrdersWithPoints,
  show,
  createInvoice,
  downloadInvoice,
  downloadInvoicePdf,
}
