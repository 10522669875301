import { addressService } from '@/_services/address.service'

export default {
  index({ commit }) {
    return addressService.index().then(
      addresses => {
        console.log(addresses)
        commit('setAddresses', addresses.data)
        return Promise.resolve(addresses.data)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  show({ commit }, { id }) {
    return addressService.show(id).then(
      address => {
        commit('setAddress', address.data)
        return Promise.resolve(address.data)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  update({ commit }, { id, params, withDefault }) {
    return addressService.update(id, params, withDefault).then(
      address => {
        commit('setAddress', address.data)
        return Promise.resolve(address.data)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  delete(_, { id }) {
    return addressService.destroy(id).then(
      () => Promise.resolve(),
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  store(_, { params }) {
    return addressService.store(params).then(
      address => Promise.resolve(address.data),
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
}
