/* export const API_URL = 'http://app-demo83871.promostore.com.mx' */
/* export const API_URL = 'http://closed-ecommerce.test' */
/* export const API_URL = 'http://promostore-distribuidores.test' */
/* export const API_URL = 'http://promostore-distribuidores.hs' */
/* export const API_URL = 'https://api.promoshopclarios.com' */
export const API_URL = 'https://api.promoshopclarioscam.com'
/* export const API_URL = 'http://localhost:8000' */

/* export const CONEKTA_API_KEY = "key_bHdk3rAUuqokthZ1gYmcSBQ" */
export const CONEKTA_API_KEY = 'key_F4ooyu5zyRdYMHjtMe2kTqA'

export const VARIANT_LABELS = {
  gender: 'Género',
  color: 'Color',
  size: 'Talla',
  brand: 'Marca',
  fragrance: 'Fragancia',
}

export const IS_CENTROAMERICA = true
